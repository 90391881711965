// Attaches submission event to month chooser submission buttons

$(document).ready(function() {
    $("form.month_chooser").submit(function(event) {
        event.preventDefault();
        var month_chooser_form = jQuery(this);
        var action_url = month_chooser_form.data("action_url");

        if (action_url) {
            var month = parseInt(month_chooser_form.find('select[name="month_year_month"]').val());
            var year = parseInt(month_chooser_form.find('select[name="month_year_year"]').val());

            if (month>0 && year>0) {
                var padded_month;
                if (month<10) {
                    padded_month = "0" + month.toString();
                } else {
                    padded_month = month.toString();
                }
                var redirect_url = action_url + year.toString() + "/" + padded_month;
                window.location.replace(redirect_url);
            } else {
                var messages_box = month_chooser_form.find('div.messages');
                if (messages_box) {
                    messages_box.text("Please select a year and month!");
                }
            }

        } else {
            console.log("No action_url parameter defined in month picker form!");
        }

    });
});