import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import Cookies from "js-cookie";

$(document).ready(function() {

    //FIXME don't duplicate the TinyMCE code from company_detail.js
    tinyMCE.baseURL = '/static/tinymce';

    tinymce.init({ selector: 'textarea.tinymce-full',
                   skin_url: tinyMCE.baseURL + '/skins/ui/oxide',
                   branding: false,
                   plugins: 'lists, link',
                   default_link_target: '_blank',
                   toolbar: 'undo redo | formatselect | bold italic underline | bullist numlist outdent indent | link image | cut copy paste',
                   menubar: false,
                   setup: function(ed) {
                       ed.on('change', function(e) { ed.save(); });
                   }
    });

    $("button[name='toggle-completed']").click(function(event) {
        console.log("complete");
        event.preventDefault();
        var button = jQuery(this);
        var id = button.data("id");
        var csrftoken = Cookies.get('csrftoken');
        $.ajax({
            url: '/rest-api/research-tasks/' + id + '/',
            type: 'PATCH',
            data: { "completed": button.data("completed")=='True' ? false : true  },
            dataType: 'json',
            headers: {
                "X-CSRFToken": csrftoken
            },
            success: function (data) {
                 if (data.completed) {
                     button.data("completed", 'True');
                     button.html("Reopen");
                     button.removeClass("btn-outline-success");
                     button.addClass("btn-outline-danger");
                     button.parent().prev().html("Just now");
                     // Show "Email research" button
                     button.next().removeClass('d-none');
                 } else {
                     button.data("completed", 'False');
                     button.html("Complete");
                     button.removeClass("btn-outline-danger");
                     button.addClass("btn-outline-success");
                     button.parent().prev().html("No");
                     // Hide "Email research" button
                     button.next().addClass('d-none');
                 }
                 button.blur();
            },
            fail: function(data) {
                // place error code here
                console.log(data);
            }
        });
    });

    $("button[name='email-research-task']").click(function (event) {
        event.preventDefault();
        let button = jQuery(this);
        const id = button.data("id");
        const csrftoken = Cookies.get('csrftoken');
        $.ajax({
                url: '/rest-api/research-tasks/' + id + '/email_research_task/',
            type: 'POST',
            headers: {
                "X-CSRFToken": csrftoken
            },
            success: function (data) {
                if (data.status) {
                    button.replaceWith("Email sent");
                } else {
                    console.log("Research task email not sent; API return status is false.");
                }
            },
            fail: function (data) {
                // place error code here
                console.log(data);
            }
        });
    });

    $("button[name='add_research_task']").click(function(event) {
        event.preventDefault();
        var button = jQuery(this);
        $("#add_research_task").collapse('show');
        button.addClass('d-none');
    });

    // Enable add research task button
    $("button[name='add_research_task']").prop('disabled', false);

    $("input[name='cancel_add_research_task']").click(function(event) {
        event.preventDefault();
        var button = jQuery(this);
        $("#add_research_task").collapse('hide');
        $("button[name='add_research_task']").removeClass('d-none');
    });

    $("form#add_research_task_form").submit(function(event) {
        event.preventDefault();
        var research_task_form = jQuery(this);
        $.post('/rest-api/research-tasks/', research_task_form.serialize(), function(data) {
            $("#add_research_task_modal").modal('hide');
            $("#research_task_table>tbody").append(data.rendered);
            $("#research_task_table>tbody>tr.empty_text").remove();
            $("#add_research_task").collapse('hide');
            $("button[name='add_research_task']").removeClass('d-none');
        },
        'json'
        )
            .fail(function(data) {
                // place error code here
                console.log(data);
            });
    });
});