import 'highcharts';
import addNoData from "highcharts/modules/no-data-to-display";
addNoData(Highcharts)

$.ajax({
  url: $("#my_investment_chart").attr("data-url"),
  dataType: 'json',
  success: function (data) {
    Highcharts.chart("my_investment_chart", data);
  }
});

$.ajax({
  url: $("#portfolio_pie").attr("data-url"),
  dataType: 'json',
  success: function (data) {
    Highcharts.chart("portfolio_pie", data);
  }
});


