var React = require('react')
var ReactDOM = require('react-dom')

class Hello extends React.Component {
    render() {
        return (
            <h1>
            Hello, React!
            </h1>
        )
    }
}

var react_container = document.getElementById('react_container')
if (react_container)
{
    ReactDOM.render(<Hello />, document.getElementById('react_container'))
}

export default Hello