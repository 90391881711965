import 'bootstrap';
import 'jquery';

require("./react_test.js");
require("./company_detail.js");
require("./investmentdecision_detail.js");
require("./index_dashboard.js");
require("./article_classification.js");
require("./uploads.js");
require("./month_chooser.js");



