import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import Cookies from "js-cookie";

function initEditButtons() {
    $("button[name='edit']").click(function(event) {
        event.preventDefault();
        var button = jQuery(this);
        var id = button.closest('.card-footer').prev().children("form[name='upsert_answer']").children("textarea")[0].id;
        tinymce.EditorManager.get(id).setContent(button.closest('.card-footer').prev().children('div.answer').html());
        button.closest('.card-footer').prev().children('div.answer').collapse('hide'); // Existing answer div
        button.closest('.card-footer').prev().children("form[name='upsert_answer']").collapse('show'); // Answer form
        button.prop("disabled", true).addClass('disabled');
    }).addClass("ready"); // Ready class indicates enabled edit button so now it's ready to be clicked
}

$(document).ready(function() {

    tinyMCE.baseURL = '/static/tinymce';

    tinymce.init({ selector: 'textarea.tinymce-full',
                   skin_url: tinyMCE.baseURL + '/skins/ui/oxide',
                   branding: false,
                   plugins: 'lists, link',
                   default_link_target: '_blank',
                   toolbar: 'undo redo | formatselect | bold italic underline | bullist numlist outdent indent | link image | cut copy paste',
                   menubar: false,
                   setup: function(ed) {
                       ed.on('change', function(e) { ed.save(); });
                       initEditButtons();
                   }
    });

    tinymce.init({ selector: 'textarea.tinymce-minimal',
               skin_url: tinyMCE.baseURL + '/skins/ui/oxide',
               branding: false,
               plugins: 'lists, link',
               default_link_target: '_blank',
               toolbar: false,
               menubar: false,
               statusbar: false,
               setup: function(ed) {
                   ed.on('change', function(e) { ed.save(); });
               }
    });

    $("#create_comment").submit(function(event) {
        event.preventDefault();
        var form = jQuery(this);
        $.post('/rest-api/comments/', form.serialize(), function(data) {
            // Add new comment to the page
            $("#comment_list").append(data.rendered);
            $("#comment_list h4:last-of-type").removeClass('d-none');
            tinymce.EditorManager.get('comment').setContent('');
        },
        'json'
        )
            .fail(function(data) {
                // place error code here
                console.log(data);
            });
    });

    $("button[name='edit']").click(function(event) {
        event.preventDefault();
        var button = jQuery(this);
        var id = button.closest('.card-footer').prev().children("form[name='upsert_answer']").children("textarea")[0].id;
        tinymce.EditorManager.get(id).setContent(button.closest('.card-footer').prev().children('div.answer').html());
        button.closest('.card-footer').prev().children('div.answer').collapse('hide'); // Existing answer div
        button.closest('.card-footer').prev().children("form[name='upsert_answer']").collapse('show'); // Answer form
        button.prop("disabled", true).addClass('disabled');
    });

    $("form").on('shown.bs.collapse', function() {
        var form = jQuery(this);
        var id = form.children('textarea')[0].id;
        tinymce.EditorManager.get(id).focus();
    });

    $("button[name='cancel']").click(function(event) {
        event.preventDefault();
        var button = jQuery(this);
        button.parent().collapse('hide'); // Answer form
        button.parent().prev().collapse('show'); // Existing answer div
        button.closest('.card-body').next().children('button').prop("disabled", false).removeClass('disabled');
    });

    $("form[name='upsert_answer']").submit(function(event) {
        event.preventDefault();
        var answer_form = jQuery(this);
        var url = '/rest-api/answers/';
        var answer_pk = answer_form.children("input[name=answer]:first").val();
        var method;
        if (answer_pk) {
            url = url + answer_pk + '/';
            method = "put";
        } else {
            method = "post";
        }
        var csrftoken = Cookies.get('csrftoken');

        $.ajax({
            url: url,
            type: method,
            data: answer_form.serialize(),
            dataType: 'json',
            headers: {
                "X-CSRFToken": csrftoken
            },
            success: function (data) {
                    answer_form.prev().html(data.content);
                    answer_form.children("input[name=answer]:first").val(data.id)
                    answer_form.closest('.card-body').next().children('small').html('By ' + data.latest_author + ' a moment ago');
                    answer_form.prev().collapse('show'); // Existing answer div
                    answer_form.collapse('hide'); // Answer form
                    answer_form.closest('.card-body').next().children('button').prop("disabled", false).removeClass('disabled');
            },
            fail: function(data) {
                // place error code here
                console.log(data);
            }
        });
    });
});