import Cookies from "js-cookie";

$(document).ready(function () {
    $("button[name='not-useful']").click(function (event) {
        event.preventDefault();
        var button = jQuery(this);
        var id = button.data("id");
        var csrftoken = Cookies.get('csrftoken');
        $.ajax({
            url: '/rest-api/articles/' + id + '/',
            type: 'PATCH',
            data: {"is_useful": false},
            dataType: 'json',
            headers: {
                "X-CSRFToken": csrftoken
            },
            success: function (data) {
                location.reload(true);
            },
            fail: function (data) {
                // place error code here
                console.log(data);
            }
        });
    });

    $("button[name='read-later']").click(function (event) {
        event.preventDefault();
        var button = jQuery(this);
        var id = button.data("id");
        var csrftoken = Cookies.get('csrftoken');
        $.ajax({
            url: '/rest-api/articles/' + id + '/bookmark_article/',
            type: 'POST',
            headers: {
                "X-CSRFToken": csrftoken
            },
            success: function (data) {
                location.reload(true);
            },
            fail: function (data) {
                // place error code here
                console.log(data);
            }
        });
    });
});